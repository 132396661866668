import { ContainerActions, ContainerState } from './types';
import ActionTypes from './constants';

export const initialState: ContainerState = {
  loadingAllBrands: true,
  brandsData: {
    data: [],
    totalPage: 0,
    totalResults: 0,
  },
  search: {
    name: '',
    categoryIds: [],
  },
  size: 40,
  page: 0,
};

function allBrandsReducer(state: ContainerState = initialState, action: ContainerActions) {
  const { type, payload } = action as any;
  switch (type) {
    case ActionTypes.ALL_BRANDS_REQUEST:
      return {
        ...state,
        search: payload.search,
        loadingAllBrands: true,
      };

    case ActionTypes.ALL_BRANDS_SUCCESS:
      if (payload.isLoadMore) {
        return {
          ...state,
          loadingAllBrands: false,
          brandsData: {
            data: state.brandsData.data?.concat(payload.response.data),
            totalPage: payload.response.totalPage,
            totalResults: payload.response.totalResults,
          },
        };
      }
      return { ...state, loadingAllBrands: false, brandsData: payload };

    case ActionTypes.ALL_BRANDS_FAILURE: {
      return { ...state, loadingAllBrands: false, brandsData: state.brandsData };
    }

    case ActionTypes.FETCH_MORE_BRANDS:
      return {
        ...state,
        loadingAllBrands: true,
        search: {
          name: state.search.name,
          categoryIds: state.search.categoryIds,
        },
        page: (state.page || 0) + 1,
        size: 40,
      };

    default:
      return state;
  }
}

export default allBrandsReducer;
