/*
 * AccountInfoPage Messages
 *
 * This contains all the text for the AccountInfoPage container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.AllBrandPage';

export default defineMessages({
  allBrands: {
    id: `${scope}.allBrands`,
    defaultMessage: 'Tất cả thương hiệu',
  },
  placeholderSearch: {
    id: `${scope}.placeholderSearch`,
    defaultMessage: 'Tìm kiếm thương hiệu',
  },
  all: {
    id: `${scope}.all`,
    defaultMessage: 'Tất cả',
  },
});
