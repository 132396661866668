import { createSelector } from 'reselect';
import { ApplicationRootState } from 'types';
import { initialState } from './reducer';

const selectAllBrandsPageDomain = (state: ApplicationRootState) => state.allBrandsPage || initialState;

const selectAllBrandsPage = () => createSelector(selectAllBrandsPageDomain, (substate) => substate);

const selectLoadingAllBrands = () => createSelector(selectAllBrandsPageDomain, (substate) => substate.loadingAllBrands);
const selectBrandsData = () => createSelector(selectAllBrandsPageDomain, (substate) => substate.brandsData);

const selectSearch = () =>
  createSelector(selectAllBrandsPageDomain, (substate) => {
    return substate.search;
  });

export default selectAllBrandsPage;
export { selectLoadingAllBrands, selectBrandsData, selectSearch };
