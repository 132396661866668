import { action, createAsyncAction } from 'typesafe-actions';
import ActionTypes from './constants';

export const applySearch = (data: any) => action(ActionTypes.APPLY_SEARCH, data);
export const fetchMore = () => action(ActionTypes.FETCH_MORE_BRANDS);

export const allBrands = createAsyncAction(
  ActionTypes.ALL_BRANDS_REQUEST,
  ActionTypes.ALL_BRANDS_SUCCESS,
  ActionTypes.ALL_BRANDS_FAILURE,
)<{}, {}, Error>();
