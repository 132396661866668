import * as qs from 'utils/queryString';

import { call, fork, put, select, take } from 'redux-saga/effects';
import { allBrands as allBrandsActions } from './actions';

import ActionTypes from './constants';
import { makeSelectLocation } from 'containers/MainLayout/selectors';
import { push } from 'connected-react-router';
import { getBrands } from 'utils/apollo';
import selectAllBrandsPage from './selectors';

function* searchFlow() {
  while (true) {
    const { payload } = yield take(ActionTypes.APPLY_SEARCH);
    const location = yield select(makeSelectLocation());
    const oldQuery = location.search.length > 0 ? location.search.slice(1) : '';
    const newQuery = qs.stringify(payload);

    if (newQuery === oldQuery) {
      continue;
    }

    yield put(
      push({
        search: newQuery,
      }),
    );
  }
}

function* getAllBrands() {
  const location = yield select(makeSelectLocation());
  const defaultSearch = {
    name: '',
    categoryIds: '',
  };
  const search: any = location.search
    ? {
        ...defaultSearch,
        ...qs.parse(location.search),
      }
    : defaultSearch;

  yield put(
    allBrandsActions.request({
      search: {
        name: search.name,
        categoryIds: search.categoryIds ? search.categoryIds.toString().split(',').map(Number) : [],
      },
    }),
  );

  const response = yield call(getBrands, {
    name: search.name,
    categoryIds: search.categoryIds ? search.categoryIds.toString().split(',').map(Number) : [],
    pagination: { page: 0, size: 40 },
  });

  if (!response.errors) {
    yield put(allBrandsActions.success(response));
  } else {
    yield put(allBrandsActions.failure(response.errors));
  }
}

function* scrollFlow() {
  while (true) {
    yield take(ActionTypes.FETCH_MORE_BRANDS);
    const allbrandsPageState = yield select(selectAllBrandsPage());
    const response = yield call(getBrands, {
      name: allbrandsPageState.search.name,
      categoryIds: allbrandsPageState.search.categoryIds,
      pagination: {
        page: allbrandsPageState.page,
        size: 40,
      },
    });
    if (!response.errors) {
      yield put(
        allBrandsActions.success({
          response: response,
          isLoadMore: true,
        }),
      );
    } else {
      yield put(allBrandsActions.failure(response.errors));
    }
  }
}

function* initData() {
  yield call(getAllBrands);
}

export default function* saga() {
  yield fork(initData);
  yield fork(searchFlow);
  yield fork(scrollFlow);
}
