import React, { useEffect, useState } from 'react';
import styled from 'styles/styled-components';
import media from 'utils/mediaStyle';
import { Input } from 'antd';
import messages from '../messages';
import translations from 'translations';
import { SearchOutlined } from '@ant-design/icons';
import { Category } from 'types/schema';
import { SearchType } from '../types';
import { FormattedMessage } from 'react-intl';

const Container = styled.div`
  margin-top: 12px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  ${media.md`
    margin-top: 20px;
    gap: 20px;
`}
`;

const CustomInput = styled(Input)`
  padding: 12px;
  border-radius: 6px;
  font-size: 16px;
  line-height: 24px;
  height: 48px;
  width: 100%;
  ${media.md`
        width: 542px;
    `}
`;

const Categories = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  flex-wrap: nowrap;
  overflow-x: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
  ${media.md`
    flex-wrap: wrap;
    gap: 12px;
`}
`;

const CategoryItem = styled.div`
  padding: 6px 12px;
  border-radius: 20px;
  border: 1px solid #f1f1f1;
  white-space: nowrap;
  font-size: 14px;
  line-height: 20px;
  color: #343538;
  cursor: pointer;
  background-color: #ffffff;
  ${(props) => props.selected && 'border-color: #43a047; color: #43a047; '}
  &:hover {
    border-color: #43a047;
  }
`;

interface IProps {
  onSearch: (data: any) => void;
  categories: Category[];
  search: SearchType;
}

const SearchSection = ({ onSearch, categories, search }: IProps) => {
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    setSearchValue(search?.name || '');
  }, [search?.name]);

  return (
    <Container>
      <CustomInput
        value={searchValue}
        placeholder={translations(messages.placeholderSearch)}
        onPressEnter={() => {
          onSearch({
            ...search,
            categoryIds:
              search.categoryIds && search.categoryIds.length > 0 ? search.categoryIds?.join(',') : undefined,
            name: searchValue !== '' ? searchValue : undefined,
          });
        }}
        onChange={(event) => {
          setSearchValue(event.target.value);
        }}
        suffix={
          <SearchOutlined
            onClick={() => {
              onSearch({
                ...search,
                categoryIds:
                  search.categoryIds && search.categoryIds.length > 0 ? search.categoryIds?.join(',') : undefined,
                name: searchValue !== '' ? searchValue : undefined,
              });
            }}
            style={{ fontSize: '21px', color: '#000000', cursor: 'pointer' }}
          />
        }
      />
      <Categories>
        <CategoryItem
          key={0}
          selected={search?.categoryIds?.length === 0}
          onClick={() => {
            onSearch({
              ...search,
              name: search.name !== '' ? searchValue : undefined,
              categoryIds: undefined,
            });
          }}
        >
          <FormattedMessage {...messages.all} />
        </CategoryItem>
        {categories?.map((category) => (
          <CategoryItem
            key={category.name}
            selected={search?.categoryIds?.includes(category.id as number)}
            onClick={() => {
              if (search?.categoryIds?.includes(category.id as number)) {
                const newCategoryIds = search.categoryIds.filter((id) => id !== category.id);
                onSearch({
                  ...search,
                  name: search.name !== '' ? searchValue : undefined,
                  categoryIds: newCategoryIds.length > 0 ? newCategoryIds.join(',') : undefined,
                });
              } else {
                onSearch({
                  ...search,
                  name: search.name !== '' ? searchValue : undefined,
                  categoryIds: search?.categoryIds?.concat(category.id as number).join(','),
                });
              }
            }}
          >
            {category.nameLocal}
          </CategoryItem>
        ))}
      </Categories>
    </Container>
  );
};

export default SearchSection;
